/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

    $.fn.slideFadeToggle  = function(speed, easing, callback) {
        return this.animate({opacity: 'toggle', height: 'toggle'}, 300);
    }; 

    $('#nav-toggle').click(function(e) {
      e.preventDefault();
      $('.navigation-wrapper').slideFadeToggle();
      $(this).find('.nav-toggle-bars').toggleClass('active');
    });

function blokkenheight() {
   
  $('.list').each(function(){
  
    $('.list-block-product',this).height('auto');

        var highestBox = 0;
        $('.list-block-product', this).each(function(){

            if($(this).height() > highestBox) {
               highestBox = $(this).height(); 
             }
        });  

        $('.list-block-product',this).height(highestBox);

  });

}

blokkenheight();

$(window).resize(function() {
  blokkenheight();

});
$('.slider-hero').each(function( index ) {
$(this).attr('hero-slider', index);
$('.slider', this).slick({
  nextArrow: '<i class="fas fa-angle-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fas fa-angle-left slick-arrow arrow-left"></i>',
  autoplay: true,
  autoplaySpeed: 7000,
  appendArrows: '[hero-slider="' + index + '"] .slider-controls .container .arrows',
  dots: true,
  appendDots: '[hero-slider="' + index + '"] .slider-controls .container',
});
});

$('.gallery-item').magnificPopup({
  type: 'image',
  gallery:{
    enabled:true
  }
});

$('.gallery-slider').each(function( index ) {
$(this).attr('gallery-slider', index);
$('.slider', this).slick({
  nextArrow: '<i class="fas fa-angle-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fas fa-angle-left slick-arrow arrow-left"></i>',
  autoplay: false,
  autoplaySpeed: 7000,
  appendArrows: '[gallery-slider="' + index + '"] .slider-controls .arrows',
  dots: true,
  appendDots: '[gallery-slider="' + index + '"] .slider-controls',
});
});

$('.slider-featured').each(function( index ) {
$(this).attr('featured-slider', index);
$('.slider', this).slick({
  nextArrow: '<i class="fas fa-angle-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fas fa-angle-left slick-arrow arrow-left"></i>',
  autoplay: false,
  autoplaySpeed: 7000,
  appendArrows: '[featured-slider="' + index + '"] .slider-controls .arrows',
  dots: true,
  appendDots: '[featured-slider="' + index + '"] .slider-controls',
});
});



  var ajaxUrl = ajaxquery.ajax_url;

  $('.section-query').each(function() {

  var page = 1;
  var qcontainer = $(this);
  var btncontainer = qcontainer.find('.more-posts');

  btncontainer.find('a').on('click',function(event){

    event.preventDefault();
    var morebtn = $(this);
    var querytype = morebtn.attr('query-type');
    var queryppp = morebtn.attr('query-ppp');

    morebtn.attr('disabled',true);
    $.post(ajaxUrl, {
        action:'more_post_ajax',
        offset: (page * queryppp),
        ppp: queryppp,
        cpt: querytype
    }).success(function(posts){
        if (posts){
        page++;
        qcontainer.find('.append-posts').append(posts);
        morebtn.attr('disabled',false);
        } else {
          btncontainer.fadeOut(100);
        }
    });

   });

  });





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
